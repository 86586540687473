import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.css'
import Draggable from "vue3-draggable";


// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const app = createApp(App)

const vuetify = createVuetify({
  components,
  directives,
  Draggable,
  icons: {
    defaultSet: 'mdi', 
  },
})


app.config.globalProperties.$http = axios
// app.config.globalProperties.$api = 'http://127.0.0.1:8000/api';
app.config.globalProperties.$api = 'https://be.catalog.tokowelly.biz.id/api';
// app.config.globalProperties.$baseURL = 'http://127.0.0.1:8000'
// app.config.globalProperties.$baseURL = 'https://be.catalog.tokowelly.biz.id'



app.use(vuetify)
app.use(router)
app.mount('#app')
