import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Toko Welly',
      meta: { title: 'Toko Welly' },
      component: () => import('./components/Catalog.vue'),
    },
    {
      path: '/product/:productId',
      name: 'Catalog Detail',
      meta: { title: 'Produk' },
      component: () => import('./components/CatalogDetail.vue'),
    },
    {
      path: '/admin/login',
      name: 'Login',
      meta: { title: 'Login' },
      component: () => import('./components/Login.vue'),
    },
    {
      path: '/admin',
      name: 'NavigationLayout',
      component: () => import('./components/NavigationLayout.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          meta: { title: 'Home' },
          component: () => import('./components/Home.vue'),
        },
        {
          path: 'manage-category',
          name: 'Manage Category',
          meta: { title: 'Category' },
          component: () => import('./components/Category.vue'),
        },
        {
          path: 'manage-product',
          name: 'Manage Product',
          meta: { title: 'Product' },
          component: () => import('./components/Product.vue'),
        },
        {
          path: 'manage-prefill',
          name: 'Manage Prefill',
          meta: { title: 'Prefill' },
          component: () => import('./components/Prefill.vue'),
        },
        // {
        //   path: 'manage-media',
        //   name: 'Manage Media',
        //   meta: { title: 'Media' },
        //   component: () => import('./components/Media.vue'),
        // },
        {
          path: 'manage-user',
          name: 'Manage User',
          meta: { title: 'User' },
          component: () => import('./components/User.vue'),
        },
      ],
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title;
//   const token = localStorage.getItem('token');
//   if (token === null) {
//     if (to.name === 'Login') {
//       next();
//     } else {
//       next({ name: 'Login' });
//     }
//   } else {
//     if (to.name === 'Login') {
//       next({ name: 'Home' });
//     } else {
//       next();
//     }
//   }
// });

router.beforeEach((to, from, next)=>{
  document.title = to.meta.title
  if(to.name === 'Home' && localStorage.getItem('token')===null) next({ name: 'Login'})
  else next()
})

router.beforeEach((to, from, next)=>{
  document.title = to.meta.title
  if(to.name === 'Login' && localStorage.getItem('token')!==null) next({ name: 'Home'})
  else next()
})

router.beforeEach((to, from, next)=>{
  document.title = to.meta.title
  if(to.name === 'Category' && localStorage.getItem('token')===null) next({ name: 'Login'})
  else next()
})

router.beforeEach((to, from, next)=>{
  document.title = to.meta.title
  if(to.name === 'Product' && localStorage.getItem('token')===null) next({ name: 'Login'})
  else next()
})

router.beforeEach((to, from, next)=>{
  document.title = to.meta.title
  if(to.name === 'Prefill' && localStorage.getItem('token')===null) next({ name: 'Login'})
  else next()
})

router.beforeEach((to, from, next)=>{
  document.title = to.meta.title
  if(to.name === 'Media' && localStorage.getItem('token')===null) next({ name: 'Login'})
  else next()
})

router.beforeEach((to, from, next)=>{
  document.title = to.meta.title
  if(to.name === 'User' && localStorage.getItem('token')===null) next({ name: 'Login'})
  else next()
})

export default router;

